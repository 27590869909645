<script>
import { LMap, LMarker, LTileLayer} from "vue2-leaflet";
//import L from "leaflet";
import { FormWizard, TabContent } from "vue-form-wizard";
import VueFormGenerator from "vue-form-generator";
//import { sameAs } from "vuelidate/lib/validators";
import '@/assets/css/form.css'
import 'leaflet/dist/leaflet.css';
import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
import {
  authMethods,
  notificationMethods
} from "@/state/helpers";

export default {
  components: {FormWizard, TabContent, "vue-form-generator": VueFormGenerator.component,  LMap, LTileLayer, LMarker},
  data() {
    return {
      model: { fullName: "", email: "", password: "", confPassword: "", name: "", lat: 33.585314, lng: -7.584968, zipCode: "", city: "", country: "", positionStr: ""},
      formOptions: {
        validationErrorClass: "has-error",
        validationSuccessClass: "has-success",
        validateAfterChanged: true
      },
      firstTabSchema:{
        fields:[{
            type: "input",
            inputType: "text",
            label: "Username",
            model: "fullName",
            min: 6,
            required:true,
            validator:VueFormGenerator.validators.string,
            styleClasses:'col-12'
        },
        {
            type: "input",
            inputType: "text",
            label: "Email",
            model: "email",
            required:true,
            validator:VueFormGenerator.validators.email,
            styleClasses:'col-12'
        },
          {
            type: "input",
            inputType: "password",
            label: "Password",
            model: "password",
            min: 6,
            required: true,
            hint: 'Minimum 6 characters',
            validator: VueFormGenerator.validators.string,
            styleClasses:'col-12'
        },
        {
            type: "input",
            inputType: "password",
            label: "Re-Password",
            model: "confPassword",
            min: 6,
            required: true,
            hint: 'Minimum 6 characters',
            validator: VueFormGenerator.validators.sameAS = value => {
              if (value != this.model.password) {
                return ["Password does not match !"];
              } else {
                return []
              }},
            styleClasses:'col-12'
        }
        ]
      },
      secondTabSchema:{
        fields:[
        {
            type: "input",
            inputType: "text",
            label: "Organisation",
            model: "name",
            min: 6,
            required: true,
            hint: 'Minimum 6 characters',
            validator:VueFormGenerator.validators.string,
            styleClasses:'col-12'
        },
          {
            type: "select",
            label: "Country",
            model: "country",
            required: true,
            validator:VueFormGenerator.validators.string,
            values: Object.values(countries.getNames("en", { select: "official" })),
            styleClasses:'col-4'
          },
          {
            type: "input",
            inputType: "text",
            label: "City",
            model: "city",
            min: 6,
            required: true,
            hint: 'Minimum 6 characters',
            validator:VueFormGenerator.validators.string,
            styleClasses:'col-4'
          },
          {
            type: "input",
            inputType: "number",
            label: "Zip Code",
            model: "zipCode",
            min: 6,
            required: true,
            hint: 'Minimum 6 characters',
            validator:VueFormGenerator.validators.number,
            styleClasses:'col-4'
          },
          /* {
            type: "input",
            inputType: "number",
            label: "Lat",
            model: "lat",
            required: true,
            validator:VueFormGenerator.validators.number,
            styleClasses:'col-5'
          }, */
          {
            type: "input",
            inputType: "text",
            label: "Location",
            model: "positionStr",
            required: true,
            validator:VueFormGenerator.validators.string,
            styleClasses:'col-12',
            buttons: [
              {
                  classes: "btn-location",
                  label: "Location",
                  styleClasses:'col-3',
                  onclick: function() {
                      this.$bvModal.show("set-position");
                      setTimeout(()=>{
                        window.dispatchEvent(new Event('resize'));
                      }, 100)
                  }
              },
            ]
          }
        ]
      },
      countryOptions: countries.getNames("fr", { select: "official" }),
      countriesArray: [],
      url: "http://tile.openstreetmap.ma/osm/{z}/{x}/{y}.png",
      attribution: '&copy; <a href="http://openstreetmap.ma/">OSM Maroc</a>',
      staticAnchor: [16, 37],
      image: "icon-site.png",
      submitted: false,
      regError: null,
      isRegisterError: false,
      registerSuccess: false
    };
  },
  /* validations: {
    confPassword: { 
      required,
      minLength: minLength(6),
      sameAsPassword: sameAs('password')
    }
  }, */
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  /* validations: {
    user: {
      fullName: { required },
      email: { required, email },
      password: { required },
      name: { required },
      lat: { required },
      lng: { required },
      zipCode: { required },
      city: { required },
      country: { required },
    }
  }, */
  created() {
    document.body.classList.add("auth-body-bg");
    this.clear()
  },
  methods: {
    ...authMethods,
    ...notificationMethods,


    onComplete: function(){
      //alert('Yay. Done!');
      this.submitted = true;
      
      const { email, fullName, password, name } = this.model;
      if (email && fullName && password && name) {
        let data = {fullName:this.model.fullName, 
                  email: this.model.email, 
                  password: this.model.password, 
                  name: this.model.name, 
                  lat: this.model.lat, 
                  lng: this.model.lng, 
                  zipCode: this.model.zipCode, 
                  city: this.model.city, 
                  country: this.model.country,
                  }
        console.log(data);
        return (
          this.registeruser(data)
              // eslint-disable-next-line no-unused-vars
              .then((user) => {
                /* this.isRegisterError = false;
                this.registerSuccess = true; */
                this.makeToast('Alert success', 'Welcome '+ user.user.fullName+' Registration Successful', 'success')
                /*if (this.registerSuccess) {
                  //this.success("Registration done");
                   this.$router.push(
                    this.$route.query.redirectFrom || { name: "login" }
                  ); 
                }*/
              })
              .catch((error) => {
                /* this.regError = error ? error[0].messages[0] : "";
                this.isRegisterError = true; */
                this.makeToast('Alert Error', error, 'danger')
              })
        );
      }
   },
    /* icon: function(){
      return L.icon({
        iconUrl: this.image,
        iconSize: [40, 40],
        iconAnchor: [20, 20]
      })
    }, */
   validateFirstTab: function(){
     return this.$refs.firstTabForm.validate();
   },
   validateSecondTab: function(){
     return this.$refs.secondTabForm.validate();
   },
   updateCoordinates(location) {
      //console.log(this.countryOptions);
      this.model.lat = location.target._latlng.lat;
      this.model.lng = location.target._latlng.lng;
      // this.newSite.location = `${this.position.lat.toFixed(6)},${this.position.lng.toFixed(6)}`;
      this.model.positionStr = `${location.target._latlng.lat.toFixed(6)},${location.target._latlng.lng.toFixed(6)}`;
      console.log(this.model.positionStr);
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true
      });
    },
    /*tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
          const { email, fullName, password, name, lat, lng, zipCode, city, country } = this.user;
          if (email && fullName && password && name && lat && lng && zipCode && city && country) {
            this.registeruser(this.user);
          }
        
      }
    }*/
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-8">
            <div class="register-bg">
             <!--  <div class="bg-overlay"></div> -->
            </div>
          </div>
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="text-center">
                  <div>
                    <a href="/" class="logo">
                      <img src="@/assets/images/HardiotIcon.png" height="70" alt="logo" />
                    </a>
                  </div>

                  <h4 class="font-size-18 mt-1">Register account</h4>
                  <!-- <b-alert
                      v-model="registerSuccess"
                      class="mt-3"
                      variant="success"
                      dismissible
                    >Registration successfull.
                  </b-alert>

                  <b-alert
                      v-model="isRegisterError"
                      class="mt-3"
                      variant="danger"
                      dismissible
                    >{{regError}}
                  </b-alert> 

                  <b-alert
                      variant="danger"
                      class="mt-3"
                      v-if="notification.message"
                      show
                      dismissible
                    >{{notification.message}}
                  </b-alert>-->
                </div>

                <div class="row">
                  <div class="col-12">
                        
                    <form-wizard @on-complete="onComplete"
                      color="#d3000f"
                      error-color="#d3000f"
                    >
                      <tab-content title="Personal details"
                                  icon="mdi mdi-account-circle" :before-change="validateFirstTab">
                          <vue-form-generator :model="model" 
                                            :schema="firstTabSchema"
                                            :options="formOptions"
                                            ref="firstTabForm"
                                            >
                                              
                        </vue-form-generator>
                        
                      </tab-content>
                      <tab-content title="Organization details"
                                  icon="ri-building-2-line" :before-change="validateSecondTab">
                      <vue-form-generator :model="model" 
                                            :schema="secondTabSchema"
                                            :options="formOptions"
                                            ref="secondTabForm"
                                            >           
                        </vue-form-generator>
                        
                      </tab-content>
                      <tab-content title="Validation"
                                  icon="mdi mdi-checkbox-marked-circle-outline">
                        <h4>Your registration is ready!</h4>
                        <!-- <div class="panel-body">
                          <pre v-if="model" v-html="prettyJSON(model)"></pre>
                        </div> -->
                      </tab-content>
                    </form-wizard>
                    <div class="mt-1 text-center">
                      <p>
                        Already have an account ?
                        <router-link
                          tag="a"
                          to="/login"
                          class="font-weight-medium text-danger"
                        >Login</router-link>
                      </p>
                        <p>
                        © 2021 nextrack.
                      </p>
                    </div>
                  </div> 
                </div>





                <!-- <div class="row">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body">
                        <form-wizard shape="tab" color="#d3000f">
                          <tab-content icon="mdi mdi-account-circle" :before-change="firstValidation">
                            <div class="row">
                              <div class="col-12" ref="firstTab">
                                <div class="form-group row mb-3" v-bind:class="{ 'has-error': $v.user.fullName.$error }">
                                  <label class="col-md-3 col-form-label" for="name">Username<span style="color: red">*</span></label>
                                  <div class="col-md-9">
                                    <input 
                                      id="fullName"
                                      v-model="user.fullName"
                                      type="text" 
                                      class="form-control" 
                                      value="Themesdesign"
                                      :class="{ 'is-invalid': submitted && $v.user.fullName.$error }"
                                      @input="$v.user.fullName.$touch()"
                                      />
                                      <div
                                          v-if="$v.user.fullName.$error && !$v.user.fullName.required"
                                          class="invalid-feedback"
                                        >Username is required.
                                      </div>
                                  </div>
                                </div>
                                <div class="form-group row mb-3">
                                  <label class="col-md-3 col-form-label" for="email">Email<span style="color: red">*</span></label>
                                  <div class="col-md-9">
                                    <input
                                      type="email"
                                      name="email"
                                      class="form-control"
                                      value="cory1979@hotmail.com"
                                    />
                                  </div>
                                </div>
                                <div class="form-group row mb-3">
                                  <label class="col-md-3 col-form-label" for="pwd">Password<span style="color: red">*</span></label>
                                  <div class="col-md-9">
                                    <input
                                      id="pwd"
                                      type="password"
                                      name="pwd"
                                      class="form-control"
                                      value="123456789"
                                    />
                                  </div>
                                </div>

                                <div class="form-group row mb-3">
                                  <label class="col-md-3 col-form-label" for="confirmpwd">Re Password<span style="color: red">*</span></label>
                                  <div class="col-md-9">
                                    <input
                                      id="confirmpwd"
                                      type="password"
                                      class="form-control"
                                      value="123456789"
                                    />
                                  </div>
                                </div>

                              </div>
                            </div>
                          </tab-content>
                          <tab-content icon="ri-building-2-line">
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group row mb-3">
                                  <label class="col-md-3 col-form-label" for="name">Organization<span style="color: red">*</span></label>
                                  <div class="col-md-9">
                                    <input type="text" name="name" class="form-control" placeholder="Enter Organization" />
                                  </div>
                                </div>
                                <div class="form-group row mb-3">
                                  <label class="col-md-3 col-form-label" for="surname">Country</label>
                                  <div class="col-md-9">
                                    <input type="text" name="country" class="form-control" placeholder="Enter Country" />
                                  </div>
                                </div>
                                <div class="form-group row mb-3">
                                  <label class="col-md-3 col-form-label" for="surname">City</label>
                                  <div class="col-md-9">
                                    <input type="text" name="city" class="form-control" placeholder="Enter City" />
                                  </div>
                                </div>
                                <div class="form-group row mb-3">
                                  <label class="col-md-3 col-form-label" for="surname">Zip Code</label>
                                  <div class="col-md-9">
                                    <input type="text" name="city" class="form-control" placeholder="Enter Zip Code" />
                                  </div>
                                </div>
                                <div class="form-group row mb-3">
                                  <label class="col-md-3 col-form-label" for="surname">Location</label>
                                  <div class="col-md-3">
                                    <input type="text" name="city" class="form-control" placeholder="Lat" :disabled="true"/>
                                  </div>
                                  <div class="col-md-3">
                                    <input type="text" name="city" class="form-control" placeholder="Lng" :disabled="true"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </tab-content>
                          <tab-content icon="mdi mdi-checkbox-marked-circle-outline">
                            <div class="row">
                              <div class="col-12">
                                <div class="text-center">
                                  <h2 class="mt-0">
                                    <i class="mdi mdi-check-all"></i>
                                  </h2>
                                  <h3 class="mt-0">Thank you !</h3>

                                  <p class="w-75 mb-2 mx-auto">
                                    Quisque nec turpis at urna dictum luctus. Suspendisse convallis dignissim eros at volutpat. In egestas mattis dui. Aliquam
                                    mattis dictum aliquet.
                                  </p>

                                  <div class="mb-3">
                                    <div class="custom-control custom-checkbox">
                                      <input id="customCheck1" type="checkbox" class="custom-control-input" />
                                      <label
                                        class="custom-control-label"
                                        for="customCheck1"
                                      >I agree with the Terms and Conditions</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </tab-content>
                        </form-wizard>
                        <div style="float: right">
                          <span style="color: red">(*) : </span>Required
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                
                <!-- <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo-dark.png" height="70" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-1">Register account</h4>
                        <p class="text-muted">Get your free nextrack account now.</p>
                      </div>

                      <div class="p-2 mt-3">
                        <b-alert
                          v-model="registerSuccess"
                          class="mt-3"
                          variant="success"
                          dismissible
                        >Registration successfull.</b-alert>

                        <b-alert
                          v-model="isRegisterError"
                          class="mt-3"
                          variant="danger"
                          dismissible
                        >{{regError}}</b-alert>

                        <b-alert
                          variant="danger"
                          class="mt-3"
                          v-if="notification.message"
                          show
                          dismissible
                        >{{notification.message}}</b-alert>
                        
                        <form class="form-horizontal" @submit.prevent="tryToRegisterIn">
                          <div class="form-group auth-form-group-custom mb-2">
                            <i class="ri-user-2-line auti-custom-input-icon"></i>
                            <label for="username">Username</label>
                            <input
                              v-model="user.fullName"
                              type="text"
                              class="form-control"
                              id="username"
                              :class="{ 'is-invalid': submitted && $v.user.username.$error }"
                              placeholder="Enter username"
                            />
                            <div
                              v-if="submitted && !$v.user.fullName.required"
                              class="invalid-feedback"
                            >Username is required.</div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-2">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="useremail">Email</label>
                            <input
                              v-model="user.email"
                              type="email"
                              class="form-control"
                              id="useremail"
                              placeholder="Enter email"
                              :class="{ 'is-invalid': submitted && $v.user.email.$error }"
                            />
                            <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                              <span v-if="!$v.user.email.required">Email is required.</span>
                              <span v-if="!$v.user.email.email">Please enter valid email.</span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-2">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">Password</label>
                            <input
                              v-model="user.password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              :class="{ 'is-invalid': submitted && $v.user.password.$error }"
                            />
                            <div
                              v-if="submitted && !$v.user.password.required"
                              class="invalid-feedback"
                            >Password is required.</div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-2">
                            <i class="ri-building-2-line auti-custom-input-icon"></i>
                            <label for="org">Organization</label>
                            <input
                              v-model="user.name"
                              type="text"
                              class="form-control"
                              id="org"
                              :class="{ 'is-invalid': submitted && $v.user.name.$error }"
                              placeholder="Enter Organization"
                            />
                            <div
                              v-if="submitted && !$v.user.name.required"
                              class="invalid-feedback"
                            >Organization is required.</div>
                          </div>
                          <div class="row g-1">
                            <div class="col-lg-4">
                              <div class="form-group auth-form-group-custom mb-2">
                                <i class="ri-flag-line auti-custom-input-icon"></i>
                                <label for="country">Country</label>
                                <input
                                  v-model="user.country"
                                  type="text"
                                  class="form-control"
                                  id="country"
                                  :class="{ 'is-invalid': submitted && $v.user.country.$error }"
                                  placeholder="Enter country"
                                />
                                <div
                                  v-if="submitted && !$v.user.country.required"
                                  class="invalid-feedback"
                                >Country is required.</div>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="form-group auth-form-group-custom mb-2">
                                <i class="ri-building-line auti-custom-input-icon"></i>
                                <label for="city">City</label>
                                <input
                                  v-model="user.city"
                                  type="text"
                                  class="form-control"
                                  id="city"
                                  :class="{ 'is-invalid': submitted && $v.user.city.$error }"
                                  placeholder="Enter city"
                                />
                                <div
                                  v-if="submitted && !$v.user.city.required"
                                  class="invalid-feedback"
                                >City is required.</div>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="form-group auth-form-group-custom mb-2">
                                <i class="ri-number-4 auti-custom-input-icon"></i>
                                <label for="zipCode">ZipCode</label>
                                <input
                                  v-model="user.zipCode"
                                  type="text"
                                  class="form-control"
                                  id="zipCode"
                                  :class="{ 'is-invalid': submitted && $v.user.zipCode.$error }"
                                  placeholder="Enter zip code"
                                />
                                <div
                                  v-if="submitted && !$v.user.zipCode.required"
                                  class="invalid-feedback"
                                >ZipCode is required.</div>
                              </div>
                            </div>
                          </div>

                          <div class="row no-gutters">
                            <div class="col-lg-4">
                               <div class="form-group auth-form-group-custom mb-2">
                                <label for="lat" style="left: 7px">Lat</label>
                                <input
                                  v-model="user.lat"
                                  type="text"
                                  class="form-control"
                                  id="lat"
                                  :class="{ 'is-invalid': submitted && $v.user.Lat.$error }"
                                  placeholder="Enter Lat"
                                  style="padding-left: 7px"
                                  :disabled="true"
                                />
                                <div
                                  v-if="submitted && !$v.user.lat.required"
                                  class="invalid-feedback"
                                >Lat is required.</div>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="form-group auth-form-group-custom mb-2;">
                              <label for="lng" style="left: 7px">Lng</label>
                              <input
                                v-model="user.lng"
                                type="text"
                                class="form-control"
                                id="lng"
                                :class="{ 'is-invalid': submitted && $v.user.lng.$error }"
                                placeholder="Enter Lng"
                                style="padding-left: 7px;"
                                :disabled="true"
                              />
                              <div
                                v-if="submitted && !$v.user.lng.required"
                                class="invalid-feedback"
                              >Lng is required.</div>
                            </div>
                            </div>
                            <div class="col-lg-4">
                                <button
                                  class="btn btn-primary ml-2 w-md waves-effect waves-light" style="height: 60px"
                                >
                                Map
                                </button>
                            </div>
                          </div>
                          
                          
                          

                          <div class="text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >Register</button>
                          </div>

                            <div class="mt-4 text-center">
                            <p class="mb-0">
                              By registering you agree to the nextrack
                              <a
                                href="#"
                                class="text-primary"
                              >Terms of Use</a>
                            </p>
                          </div>
                        </form>
                      </div>

                      <div class="mt-1 text-center">
                        <p>
                          Already have an account ?
                          <router-link
                            tag="a"
                            to="/login"
                            class="font-weight-medium text-primary"
                          >Login</router-link>
                        </p>
                          <p>
                          © 2021 nextrack. Crafted with
                          <i class="mdi mdi-heart text-danger"></i> by DigiEye
                        </p>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <b-modal
          id="set-position"
          centered
          size="lg"
          :hide-header="true"
          @close="updateCoordinates"
          :options="{ zoomControl: true }"
        >
          <!-- <gmap-map :center="{ lat: 33.585314, lng: -7.584968 }" :zoom="7" style="height:  560px">
            <GmapMarker
              :position="position"
              :draggable="true"
              @dragend="updateCoordinates($event)"
            />
          </gmap-map> -->
          <l-map style="height: 560px;" :zoom="5" :min-zoom="5" :center="{ lat: 33.585314, lng: -7.584968 }">
            <l-tile-layer :url="url" :attribution="attribution"/>
            <l-marker
              id="marker"
              style="height: 30px"
              v-if="model.lat"
              @dragend="updateCoordinates($event)"
              :draggable="true"
              :lat-lng="{ lat: model.lat, lng: model.lng }"
            >
             <!--  <l-icon 
                :icon-anchor="staticAnchor" 
                icon-url="@/assets/images/icon-site.svg">
              </l-icon> -->
            </l-marker>
          </l-map>
          <template v-slot:modal-footer="{ ok }">
            <b-button size="sm" variant="success" @click="ok()" > OK </b-button>
          </template>
           <!-- <LMap></LMap> -->
        </b-modal>
      </div>
    </div>
  </div>
</template>
<style scoped>
div /deep/ .vue-form-wizard .wizard-icon-circle .wizard-icon-container{
  border-radius: 50%;
}
</style>